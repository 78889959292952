import React, { useEffect, useRef } from "react";
import anychart from "anychart";
import "./AnychartThemes/market_forecast.js";

import topoJsonEENAML from "../../topoJSON/topoJsonEENAML.json";
import topoJsonNEALM from "../../topoJSON/topoJsonNEALM.json";

export const AnychartSimplifiedGlobalFigure = ({
	toFigureGlobalData,
	toFigureGlobalDataGeoValues,
	segmentationTitles,
	sumTitle,
	segmentationRegionNameAbr,
	valueDisplayOption,
	decimalsOption,
	years,
}) => {
	const AnychartSimplifiedGlobalFigureRef = useRef(null);

	// useEffect(() => {
	//     // console.log('toFigureGlobalData',toFigureGlobalData);
	//     // console.log('toFigureGlobalDataGeoValues',toFigureGlobalDataGeoValues[0]);
	//     // console.log('segmentationTitles',segmentationTitles);
	//     // console.log('sumTitle',sumTitle);
	//     //
	//     // console.log('valueDisplayOption',valueDisplayOption);
	//     // console.log('decimalsOption',decimalsOption);
	//     // console.log('years',years);
	// }, [])

	useEffect(() => {
		let chart = anychart;

		const replacedNames = updateNamesPositionForRegions(toFigureGlobalData);

		const dataSet = chart.data.set(replacedNames);

		let map = chart.map();

		// // THEMES
		chart.theme("marketForecast");

		// LICENSE KEY
		chart.licenseKey("marketforecast.com-f857f242-9dccc95c");

		const currentYear = new Date().getFullYear();
		// CREDITS
		map.credits()
			.enabled(true)
			.url("https://www.marketforecast.com")
			.logoSrc("https://www.marketforecast.com/favicon.ico")
			.text(`© Market Forecast ${currentYear}`);

		const topoJSONValidated = validateTopoJSON(replacedNames);

		map.geoData(topoJSONValidated);

		map.maxBubbleSize(60);
		map.minBubbleSize(30);

		const series_1 = map.bubble(dataSet.mapAs({ id: 0, size: 5 }));
		series_1.name("% Market volumes FY-LY");

		series_1.tooltip(true);
		series_1.labels(true);

		series_1
			.labels()
			.useHtml(true)
			.fontColor("white")
			.hAlign("center")
			.vAlign("middle")
			.format((context) => {
				const size = context.getData("size");
				const index = context.index;
				const regionAbr = segmentationRegionNameAbr[index];

				return `
                    <span style="font-size: 10px;">${regionAbr}</span><br>
                    <span style="font-size: 18px; font-weight: bold;">${size}%</span>
                `;
			});

		series_1.normal().fill("#003A70", 1.0);
		series_1.hovered().fill("#003A70", 0.8);
		series_1.selected().fill("#003A70", 0.9);

		const series_2 = map.choropleth(
			dataSet.mapAs({
				id: 0,
				marketSizeFY: 1,
				marketSizeLY: 2,
				sumFYLY: 3,
				cagr: 4,
			})
		);
		series_2.name("RegionOverview ");

		// console.log('valueDisplayOption',valueDisplayOption);

		const valueDisplayOptions = valueDisplayOption;
		const FY = years[0];
		const LY = years[1];
		const sumTitles = sumTitle;

		series_2.tooltip().format(function () {
			const marketSizeFY = this.getData("marketSizeFY");
			const marketSizeLY = this.getData("marketSizeLY");
			const sumFYtoLY = this.getData("sumFYLY");
			const cagr = this.getData("cagr");

			return (
				`Market size ${FY}: ${marketSizeFY} US$ ${valueDisplayOptions}\n` +
				`Market size ${LY}: ${marketSizeLY} US$ ${valueDisplayOptions}\n` +
				`${sumTitles}: ${sumFYtoLY} US$ ${valueDisplayOptions}\n` +
				`CAGR ${FY}-${LY}}: ${cagr}`
			);
		});

		map.title(`Market share ${sumTitles} by ${segmentationTitles} `);
		map.container(AnychartSimplifiedGlobalFigureRef.current).draw();

		return () => {
			map.dispose();
		};
	}, []);

	const updateNamesPositionForRegions = (data) => {
		const replacements = {
			"North America": "North America",
			Europe: "Europe",
			"Asia-Pacific": "Asia Pacific",
			"Asia Pacific": "Asia Pacific",
			"Middle-East & Africa": "Middle East & Africa",
			"Middle East & Africa": "Middle East & Africa",
			"Latin America": "Latin America",
			// adding for eenaml EENAML
			"Europe EU-27": "Europe EU-27",
			"Europe Non EU-27": "Europe Non EU-27",
			// "Asia-Pacific": "Asia-Pacific"
		};

		return data.map((subarray) => {
			const updatedFirstPosition =
				replacements[subarray[0] || subarray[0]];
			return [updatedFirstPosition, ...subarray.slice(1)];
		});
	};

	const validateTopoJSON = (dataSet) => {
		let checkTopoJSON = dataSet;
		let result = checkTopoJSON
			.map((subarray) => subarray[0])
			.sort()
			.join(",");

		// 5 regions,
		let fiveRegionsStackNEALM = [
			"North America",
			"Europe",
			"Asia Pacific",
			"Middle East & Africa",
			"Latin America",
		]
			.sort()
			.join(",");

		let sixRegionsStackEENAML = [
			"Europe EU-27",
			"Europe Non EU-27",
			"North America",
			"Asia Pacific",
			"Middle East & Africa",
			"Latin America",
		]
			.sort()
			.join(",");

		switch (result) {
			case fiveRegionsStackNEALM:
				return topoJsonNEALM;
			case sixRegionsStackEENAML:
				return topoJsonEENAML;
			default:
				return false;
		}
	};

	return (
		<div
			ref={AnychartSimplifiedGlobalFigureRef}
			style={{ width: "100%", height: "600px" }}
		></div>
	);
};
