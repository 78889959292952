import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useNotifications } from "../notifications/Notifications";
import { service } from "../services/ForgotPassword";

import { Loading } from "../components/Loading";

export function ResetPassword() {
	let navigate = useNavigate();
	let notifications = useNotifications();
	const [loading, setLoading] = useState(false);

	function handleSubmit(event) {
		event.preventDefault();
		setLoading(true);

		let formData = new FormData(event.currentTarget);

		let vCode = formData.get("inputCode");
		let password = formData.get("newPassword");
		let repeat = formData.get("repeatPassword");

		notifications.promise(
			service
				.ResetPassword(vCode, password, repeat, (data) => {
					let eml = data.emailaddress;
					navigate(`/login?e=${eml}&isreset=true`);
				})
				.catch(() => {
					setLoading(false);
				})
				.finally(() => {
					setLoading(false);
				})
		);
	}

	return (
		<div className="col-lg-5">
			<div className="card shadow-lg border-0 rounded-lg mt-5">
				{loading ? (
					<div className="loading-overlay">
						<Loading message={"Loading"} />
					</div>
				) : null}
				<div className="card-header">
					<h3 className="text-center font-weight-light my-4">
						Create new password
					</h3>
				</div>
				<div className="card-body">
					<div className="small mb-3 text-muted">
						We sent a verification code to your email. <br />
						Enter the code from the email in the field below and
						create a new password.
					</div>
					<form onSubmit={handleSubmit}>
						<div className="form-floating mb-3">
							<input
								className="form-control"
								name="inputCode"
								type="text"
								placeholder="inputCode"
								required
							/>
							<label htmlFor="inputCode">Verification Code</label>
						</div>
						<div className="form-floating mb-3">
							<input
								className="form-control"
								name="newPassword"
								type="password"
								placeholder="Password"
								required
							/>
							<label htmlFor="newPassword">New Password</label>
						</div>
						<div className="form-floating mb-3">
							<input
								className="form-control"
								name="repeatPassword"
								type="password"
								placeholder="Password"
								required
							/>
							<label htmlFor="repeatPassword">
								Repeat Password
							</label>
						</div>
						<div className="d-flex align-items-center justify-content-between mt-4 mb-0">
							<Link to="/login" className="small">
								Return to login
							</Link>
							<button type="submit" className="btn btn-dark">
								Save New Password
							</button>
						</div>
					</form>
					<hr />
					<div className="small mt-3 mb-3 text-muted">
						Hint: Password must be at least 8 characters long and
						requires 1 digit, 1 special character, 1 upper and 1
						lower case character.
					</div>
				</div>

				<div className="card-footer text-center py-3">
					<div className="small">
						<Link to="/forgotpassword">
							Didn't receive the pin? Resend pin!
						</Link>
					</div>
				</div>
			</div>
		</div>
	);
}
