import React, { useState } from "react";
import { Link } from "react-router-dom";
import { service } from "../services/ForgotPassword";
import { useNavigate } from "react-router";
import { useNotifications } from "../notifications/Notifications";

import { Loading } from "../components/Loading";

export function ForgotPassword() {
	let navigate = useNavigate();
	let notifications = useNotifications();

	const [loading, setLoading] = useState(false);

	function handleSubmit(event) {
		event.preventDefault();
		setLoading(true);

		let formData = new FormData(event.currentTarget);
		let emailAddress = formData.get("inputEmail");

		notifications.promise(
			service
				.ForgotPassword(emailAddress, () => {
					navigate("/resetpassword");
				})
				.catch(() => {
					setLoading(false);
				})
				.finally(() => {
					setLoading(false);
				})
		);
	}

	return (
		<div className="col-lg-5">
			<div className="card shadow-lg border-0 rounded-lg mt-5">
				{loading ? (
					<div className="loading-overlay">
						<Loading message={"Loading"} />
					</div>
				) : null}
				<div className="card-header">
					<h3 className="text-center font-weight-light my-4">
						Password Recovery
					</h3>
				</div>
				<div className="card-body">
					<div className="small mb-3 text-muted">
						Enter your email address and we will send you a link to
						reset your password.
					</div>
					<form onSubmit={handleSubmit}>
						<div className="form-floating mb-3">
							<input
								className="form-control"
								name="inputEmail"
								type="email"
								placeholder="name@example.com"
								required
							/>
							<label htmlFor="inputEmail">Email address</label>
						</div>
						<div className="d-flex align-items-center justify-content-between mt-4 mb-0">
							<Link className="small" to="/login">
								Return to login
							</Link>
							<button type="submit" className="btn btn-dark">
								Reset Password
							</button>
						</div>
					</form>
				</div>
				<div className="card-footer text-center py-3">
					<div className="small">
						<a
							href="https://www.marketforecast.com"
							target="_blank"
						>
							Need an account? Sign up on MarketForecast.com!
						</a>
					</div>
				</div>
			</div>
		</div>
	);
}
